export type State = {
  subscriptionDetails: {
    subscription_id: number | null;
    subscription_name: string;
    monthly_price: number | null;
    gross_price: number | null;
    current_subscription_status: string;
  };
  nextSubscriptionDetails: {
    subscription_id: number | null;
    subscription_name: string;
    monthly_price: number | null;
    payment_interval?: string;
    isTier3or4?: boolean;
    gross_price: number | null;
    downgrade?: boolean;
  };
  subscription_status: string;
  refreshData: boolean;
};

export type Action =
  | {
      type: "SET_SUBSCRIPTION_DETAILS";
      payload: {
        subscription_id: number;
        subscription_name: string;
        monthly_price: number;
        gross_price: number;
        current_subscription_status: string;
      };
    }
  | {
      type: "SET_NEXT_SUBSCRIPTION_DETAILS";
      payload: {
        subscription_id: number;
        subscription_name: string;
        monthly_price: number;
        isTier3or4?: boolean;
        gross_price: number;
        downgrade?: boolean;
        payment_interval?: string;
      };
    }
  | {
      type: "SET_SUBSCRIPTION_STATUS";
      payload: {
        subscription_status: string;
      };
    }
  | {
      type: "REFRESH_SUBSCRIPTIONS";
    };

const initialState: State = {
  subscriptionDetails: {
    subscription_id: null,
    subscription_name: "",
    monthly_price: null,
    gross_price: null,
    current_subscription_status: "",
  },
  nextSubscriptionDetails: {
    subscription_id: null,
    subscription_name: "",
    monthly_price: null,
    payment_interval: "",
    gross_price: null,
  },
  subscription_status: "",
  refreshData: false,
};

export const subscriptionsReducer = (
  state = initialState,
  action: Action
): State => {
  switch (action.type) {
    case "SET_SUBSCRIPTION_DETAILS":
      return {
        ...state,
        subscriptionDetails: {
          subscription_id: action.payload.subscription_id,
          subscription_name: action.payload.subscription_name,
          monthly_price: action.payload.monthly_price,
          gross_price: action.payload.gross_price,
          current_subscription_status:
            action.payload.current_subscription_status,
        },
      };
    case "SET_NEXT_SUBSCRIPTION_DETAILS":
      return {
        ...state,
        nextSubscriptionDetails: {
          subscription_id: action.payload.subscription_id,
          subscription_name: action.payload.subscription_name,
          monthly_price: action.payload.monthly_price,
          payment_interval: action.payload.payment_interval,
          isTier3or4: action.payload.isTier3or4,
          gross_price: action.payload.gross_price,
          downgrade: action.payload.downgrade,
        },
      };
    case "SET_SUBSCRIPTION_STATUS":
      return {
        ...state,
        subscription_status: action.payload.subscription_status,
      };
    case "REFRESH_SUBSCRIPTIONS":
      return {
        ...state,
        refreshData: !state.refreshData,
      };
    default:
      return state;
  }
};
