import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";

import { pageReducer, modalDisplayEpic, toastDisplayEpic } from "./pageReducer";

import { usersReducer } from "./usersReducer";
import { paymentCardsReducer } from "./paymentCardsReducer";
import { transactionHistoryReducer } from "./transactionHistoryReducer";
import { tableReducer } from "./tableReducer";
import { rightPopOutReducer } from "./rightPopOutReducer";
import { modalReducer } from "./modalReducer";
import { notificationsReducer } from "./notificationsReducer";
import { toastReducer } from "./toastReducer";
import { accessibilityReducer } from "./accessibilityReducer";
import { subscriptionsReducer } from "./subscriptionsReducer";

import { reducer as auth, authEpic } from "./auth";

import {
  AuthStateStatus,
  NormalizedNavItems,
  ClientType,
} from "types/AuthTypes";
import { RequestStatus } from "types/BasicTypes";
//import { OKResponse } from "types/ApiResponseTypes";

import { AxiosError, AxiosResponse } from "axios";
import { ViewPanoramasDetails } from "pages/ViewPanoramasPage/models";
import { registerReducer as register } from "./register";
import { reportSectionReducer as reportSection } from "./reportSection";

export interface Action<T = {}> {
  type: string;
  payload: T;
  toast?: { message: string; milliseconds: number };
  errorMessage?: { error: Error };
}

export type ResponseAction<T> = {
  type: string;
  payload: AxiosError | AxiosResponse<T>;
};

export type AuthState = {
  status: AuthStateStatus;
  subscriptionStatus: string;
  appVisible: boolean;
  errors: { fieldName: string; messageCode: string }[];
  message: string;
  navigation: NormalizedNavItems;
  client_type: ClientType;
  primary_user: boolean;
  b_users_feature: boolean;
  panos?: ViewPanoramasDetails[] | null;
  b_can_add_account_users: boolean;
  client_logo: string;
};

export type ResponseState<T> = {
  status: RequestStatus;
  response: AxiosError | AxiosResponse<T> | null;
};

// export function* rootSaga() {
//   yield all([toastDisplaySaga(), modalDisplaySaga()]);
// }

export const rootEpic = combineEpics(
  toastDisplayEpic,
  modalDisplayEpic,
  authEpic
);

export const rootReducer = combineReducers({
  // General reducers go here
  auth,
  pageReducer,
  tableReducer,
  rightPopOutReducer,
  modalReducer,
  accessibilityReducer,
  // Page reducers go here
  usersReducer,
  paymentCardsReducer,
  transactionHistoryReducer,
  notificationsReducer,
  toastReducer,
  subscriptionsReducer,
  register,
  reportSection,
});

export type AppState = ReturnType<typeof rootReducer>;
