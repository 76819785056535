import React, { useState, useRef, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { endpoints } from "../../serverDetails";
//import ChunkyInput from '../../components/SignInComponents/ChunkyInput/ChunkyInput';
import axios from "api";
import Helmet from "react-helmet";
import QrReader from "react-qr-reader";
import { AppState } from "../../reducers/rootReducer";
import {
  RegistrationSteps,
  RegistrationStepActions,
} from "pages/Registration/Registration";
import { CMSGeneratedContentPartial } from "pages/CMSGeneratedContentPage/CMSGeneratedContentPage";

interface SubscriptionProps {
  navigate?: any;
  subscription?: number;
  setSubscription: (x: number) => void;
  setSubscriptionName?: (x: string) => void;
  setSubscriptionPrice?: (x: number | string) => void;
  setSubscriptionGross?: (x: number) => void;
  setActivePage?: (y: string) => void;
  setPromoCodeType?: (y: string) => void;
  setPromoCodeValue?: (x: number) => void;
  data?: Data;
  reload?: () => void;
  usersPromoCode?: string;
  setUsersPromoCode?: (p: string) => void;
  promoCodeVisible?: boolean;
  setPromoCodeVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  onLastStep?: boolean;
  setDisplayError?: (v: string) => void;
  disableCardInput?: boolean;
  setDisableCardInput?: React.Dispatch<React.SetStateAction<boolean>>;
  setFullDiscount?: React.Dispatch<React.SetStateAction<boolean>>;
  setOnLastStep?: React.Dispatch<React.SetStateAction<boolean>>;
  registrationStep?: RegistrationSteps;
  setRegistrationStep?: React.Dispatch<SetStateAction<RegistrationSteps>>;
  registrationStepActions?: RegistrationStepActions;
}

type Data = {
  details: SubscriptionDetails;
};

type SubscriptionDetails = {
  current_subscription_id?: number;
  current_subscription_status?: string;
  list: SubscriptionItem[];
  bShowExtraTiers: boolean;
  b_hide_pause_button?: number;
};

type SubscriptionItem = {
  subscription_id: number;
  subscription_name: string;
  description: string;
  monthly_price: number | null;
  yearly_price: number | null;
  gross_price: number;
  b_recurring_payment_needed: boolean;
  purchase_type: string;
};

const Subscription = (props: SubscriptionProps) => {
  const dispatch = useDispatch();
  const subscriptionStatus = useSelector(
    (state: AppState) => state.auth.subscriptionStatus
  );
  const subscriptionState = useSelector(
    (state: AppState) => state.subscriptionsReducer.subscriptionDetails
  );

  const [errorMessage, setErrorMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleToast = (message: string) => {
    dispatch({ type: "SHOW_TOAST", message: message });
    window.setTimeout(() => {
      dispatch({ type: "HIDE_TOAST" });
    }, 3000);
  };

  const unpauseSubscription = (id: number) => {
    let postObj = {
      subscription_id: id,
    };

    setSubmitting(true);
    axios
      .post(endpoints.subscription.unpauseSubscription, postObj)
      .then(res => {
        if (res.data.status === "1") {
          setSubmitting(false);
          dispatch({
            type: "REFRESH_SUBSCRIPTIONS",
          });
          handleToast("Subscription successfully restarted");
        } else {
          setSubmitting(false);
          handleToast("There was a problem, please try again");
        }
      })
      .catch(err => {
        setSubmitting(false);
        handleToast("There was a problem, please try again");
        console.log(err);
      });
  };

  const verifyPromoCode = (
    subscription_id: number | undefined,
    promoCode: string | undefined
  ) => {
    // TODO add in subscription id as second param and as second field in endpoint call
    axios
      .get(endpoints.register.getTransactionInfo, {
        params: {
          subscription_id,
          ...(promoCode && { promo_code: promoCode }),
        },
      })
      .then(res => {
        if (res.data.status === "1") {
          props.setPromoCodeType &&
            props.setPromoCodeType(res.data.details.promo_code_type);
          props.setPromoCodeValue &&
            props.setPromoCodeValue(res.data.details.promo_value);
          if (props.onLastStep) {
            props.setDisplayError && props.setDisplayError("");
            props.setActivePage && props.setActivePage("Summary");
            props.navigate && props.navigate("/register/summary");
          } else {
            props.setActivePage && props.setActivePage("PaymentCard");
            props.navigate && props.navigate("/register/payment-card");
          }
        }
      })
      .catch(err => {
        setErrorMessage("This code is invalid");
      });
  };

  const [result, setResult] = useState<string>("");
  const [noCamera, setNoCamera] = useState<boolean>(false);
  const [scannerLoading, setScannerLoading] = useState<boolean>(true);
  const [legacyMode, setLegacyMode] = useState<boolean>(false);
  const [QRError, setQRError] = useState<boolean>(false);
  const [scanCode, setScanCode] = useState<boolean>(false);
  const QRRef = useRef<any>(null);

  const hideScanner = {
    display: "none",
  };

  const uploadQRImage = () => {
    QRRef.current && QRRef.current.openImageDialog();
  };

  const handleError = (err: Error) => {
    setNoCamera(true);
    setLegacyMode(true);
  };

  const handleLoad = () => {
    window.setTimeout(() => {
      setScannerLoading(false);
    }, 500);
  };

  const handleScan = (data: any) => {
    if (data) {
      const regex = /promo_code=([\s\S]*)/;
      const t = data.match(regex);
      if (t) {
        setQRError(false);
        setErrorMessage("");
        setScanCode(false);
        setResult(t[1]);
        props.setUsersPromoCode && props.setUsersPromoCode(t[1]);
      } else {
        setQRError(true);
        setErrorMessage("");
      }
    } else {
      if (legacyMode) {
        setErrorMessage("Invalid image type");
      }
    }
  };

  React.useEffect(() => {
    if (subscriptionStatus !== "Initial") {
      if (subscriptionStatus === "Subscription_Paused") {
        handleToast("Please restart your subscription to continue");
      }
      if (
        subscriptionStatus === "Auto-Renewal-Failure" ||
        subscriptionStatus === "None"
      ) {
        handleToast("Please enter your payment details");
      }
    }

    //eslint-disable-next-line
  }, [subscriptionStatus]);

  //React.useEffect(() => {
  //  if (subscriptionStatus !== "Valid" && subscriptionStatus !== "Initial") {
  //    handleToast("Please restart your subscription to continue");
  //  }
  //  //eslint-disable-next-line
  //}, []);

  return (
    <>
      <Helmet>
        <title>Skippy Scout: Subscription</title>
      </Helmet>
      <div className={`${!props.setActivePage ? "SubscriptionWrapper" : ""}`}>
        <div className="Register">
          {props.setActivePage ? (
            <div className="FlexContainer FlexContainerSpace">
              <h1>Subscription</h1>
              {/* <p className="StepCount">Step 2/4</p> */}
            </div>
          ) : (
            <h1>Subscription</h1>
          )}

          <ul className="FlexContainer RegistrationOptions">
            {props.data &&
              props.data.details.list.map((s, i, detailsList) => {
                const currentSubIndex = detailsList.findIndex(
                  sub =>
                    sub.subscription_id === subscriptionState.subscription_id
                );

                const noMonthlyPrice =
                  s.monthly_price === 0 || s.monthly_price === null;
                const noYearlyPrice =
                  s.yearly_price === 0 || s.yearly_price === null;
                const monthlyPrice =
                  s.monthly_price !== 0 && s.monthly_price !== null;
                const yearlyPrice =
                  s.yearly_price !== 0 && s.yearly_price !== null;
                const displayPrice =
                  s.subscription_name === "Tier 1"
                    ? "Free"
                    : noMonthlyPrice && yearlyPrice
                    ? `£${s.yearly_price}`
                    : monthlyPrice && noYearlyPrice
                    ? `£${s.monthly_price}`
                    : noMonthlyPrice && noYearlyPrice
                    ? "Free"
                    : `£${s.monthly_price}`;
                const priceExtraInfo =
                  s.subscription_name === "Tier 1"
                    ? " for the first month then "
                    : "";
                const priceAdditionalCharge =
                  s.subscription_name === "Tier 1" ? `£${s.monthly_price}` : "";
                const numericalPrice =
                  noMonthlyPrice && yearlyPrice
                    ? s.yearly_price
                    : monthlyPrice && noYearlyPrice
                    ? s.monthly_price
                    : noMonthlyPrice && noYearlyPrice
                    ? 0
                    : s.monthly_price;
                const paymentInterval =
                  noMonthlyPrice && yearlyPrice
                    ? `/year (+VAT)`
                    : monthlyPrice && noYearlyPrice
                    ? `/month (+VAT)`
                    : noMonthlyPrice && noYearlyPrice
                    ? ""
                    : `/month (+VAT)`;

                if (
                  props.subscription &&
                  props.subscription === s.subscription_id
                ) {
                  props.setSubscriptionName &&
                    props.setSubscriptionName(s.subscription_name);
                  props.setSubscriptionPrice &&
                    props.setSubscriptionPrice(displayPrice);
                  props.setSubscriptionGross &&
                    props.setSubscriptionGross(s.gross_price);
                }
                return (
                  <li
                    key={i}
                    className={`SubscriptionOption 
                       SubscriptionNoToggle
                       ${
                         props.data &&
                         props.data.details.current_subscription_id ===
                           s.subscription_id
                           ? "Highlighted"
                           : ""
                       }
                       ${
                         props.subscription &&
                         props.subscription === s.subscription_id
                           ? "Highlighted"
                           : ""
                       }`}
                    onClick={() => {
                      if (props.setActivePage) {
                        props.setSubscription(s.subscription_id);
                        props.setSubscriptionName &&
                          props.setSubscriptionName(s.subscription_name);
                        props.setSubscriptionPrice &&
                          props.setSubscriptionPrice(displayPrice);
                        props.setSubscriptionGross &&
                          props.setSubscriptionGross(s.gross_price);
                      }
                    }}
                  >
                    <span className="Holder">
                      {props.setActivePage && (
                        <span className="Top">
                          <span className="CheckImg">
                            <span className="Circle" />
                          </span>
                        </span>
                      )}

                      <span className="Middle">
                        <span className="">
                          <h2>{s.subscription_name}</h2>
                          <div
                            className={`PriceMonth ${
                              priceExtraInfo ? "PriceWrap" : ""
                            }`}
                          >
                            <h2 className="Price">{displayPrice}</h2>
                            {priceExtraInfo ? (
                              <>
                                &nbsp;&nbsp;
                                <p className="Month">{priceExtraInfo}</p>
                              </>
                            ) : (
                              ""
                            )}
                            {priceAdditionalCharge ? (
                              <>
                                &nbsp;&nbsp;
                                <h2 className="Price">
                                  {priceAdditionalCharge}
                                </h2>
                              </>
                            ) : (
                              ""
                            )}
                            <p className="Month">{paymentInterval}</p>
                          </div>
                          <div
                            className="Description"
                            dangerouslySetInnerHTML={{ __html: s.description }}
                          ></div>
                        </span>
                      </span>

                      <div className="Continue">
                        {props.data &&
                          props.data.details.current_subscription_id ===
                            s.subscription_id &&
                          props.data.details.current_subscription_status ===
                            "Valid" &&
                          props.data.details.b_hide_pause_button === 0 && (
                            <button
                              className={`Btn BtnFullWidth BtnWhite`}
                              onClick={() => {
                                dispatch({
                                  type: "OPEN_MODAL",
                                  modalOpen: "SUBSCRIPTION_PAUSE",
                                });
                              }}
                            >
                              Pause subscription
                            </button>
                          )}

                        {props.data &&
                          props.data.details.current_subscription_id ===
                            s.subscription_id &&
                          props.data.details.current_subscription_status ===
                            "Auto-Renewal-Failure" && (
                            <button
                              className={`Btn BtnFullWidth BtnWhite`}
                              onClick={() => {
                                dispatch({
                                  type: "SET_NEXT_SUBSCRIPTION_DETAILS",
                                  payload: {
                                    subscription_id: s.subscription_id,
                                    subscription_name: s.subscription_name,
                                    monthly_price: numericalPrice,
                                    payment_interval: paymentInterval,
                                    gross_price: s.gross_price,
                                  },
                                });
                                dispatch({
                                  type: "OPEN_RIGHT_POP_OUT",
                                  rightPopOutOpen: "SUBSCRIPTION_UPGRADE",
                                });
                              }}
                            >
                              Pay now
                            </button>
                          )}

                        {props.data &&
                          props.data.details.current_subscription_id ===
                            s.subscription_id &&
                          props.data.details.current_subscription_status ===
                            "None" && (
                            <button
                              className={`Btn BtnFullWidth BtnWhite`}
                              onClick={() => {
                                dispatch({
                                  type: "SET_NEXT_SUBSCRIPTION_DETAILS",
                                  payload: {
                                    subscription_id: s.subscription_id,
                                    subscription_name: s.subscription_name,
                                    monthly_price: numericalPrice,
                                    payment_interval: paymentInterval,
                                    gross_price: s.gross_price,
                                  },
                                });
                                dispatch({
                                  type: "OPEN_RIGHT_POP_OUT",
                                  rightPopOutOpen: "SUBSCRIPTION_UPGRADE",
                                });
                              }}
                            >
                              Pay now
                            </button>
                          )}

                        {props.data &&
                          props.data.details.current_subscription_id ===
                            s.subscription_id &&
                          props.data.details.current_subscription_status ===
                            "Subscription_Paused" && (
                            <button
                              className={`Btn BtnFullWidth BtnWhite`}
                              onClick={() => {
                                if (props.data) {
                                  unpauseSubscription(
                                    props.data.details.current_subscription_id!
                                  );
                                }
                              }}
                            >
                              Restart subscription
                            </button>
                          )}

                        {props.data &&
                          props.data.details.current_subscription_id !==
                            s.subscription_id &&
                          !props.setActivePage && (
                            <button
                              className={`Btn BtnFullWidth`}
                              onClick={() => {
                                dispatch({
                                  type: "SET_NEXT_SUBSCRIPTION_DETAILS",
                                  payload: {
                                    subscription_id: s.subscription_id,
                                    subscription_name: s.subscription_name,
                                    monthly_price: numericalPrice,
                                    payment_interval: paymentInterval,
                                    gross_price: s.gross_price,
                                    downgrade: i < currentSubIndex,
                                    isTier3or4: currentSubIndex === -1,
                                  },
                                });
                                dispatch({
                                  type: "OPEN_RIGHT_POP_OUT",
                                  rightPopOutOpen: "SUBSCRIPTION_UPGRADE",
                                });
                              }}
                            >
                              {props.data.details
                                .current_subscription_status === "None" ||
                              props.data.details.current_subscription_status ===
                                "Auto-Renewal-Failure" ||
                              currentSubIndex === -1
                                ? "Pay now"
                                : `${
                                    i < currentSubIndex
                                      ? "Downgrade "
                                      : "Upgrade "
                                  }subscription`}
                            </button>
                          )}
                      </div>
                    </span>
                  </li>
                );
              })}
            {props.data && props.data.details.bShowExtraTiers ? (
              <li
                className={`SubscriptionOption SubscriptionNoToggle ${
                  props.data &&
                  props.data.details.current_subscription_id !== 1000 &&
                  props.data.details.current_subscription_id !== 1001
                    ? "Highlighted"
                    : ""
                }`}
              >
                <CMSGeneratedContentPartial customURL="subcontactus" />
              </li>
            ) : (
              ""
            )}
          </ul>
          {props.setActivePage && (
            <>
              <div className="Continue">
                <div className="RememberForgot PromoInput">
                  <div className="Checkbox">
                    <input
                      key={"isPromoVisible"}
                      name="promo_code"
                      type="checkbox"
                      id="promo_code"
                      defaultChecked={props.promoCodeVisible}
                      onChange={() =>
                        props.setPromoCodeVisible &&
                        props.setPromoCodeVisible(prev => {
                          props.setDisableCardInput &&
                            props.setDisableCardInput(false);
                          props.setPromoCodeType && props.setPromoCodeType("");
                          props.setPromoCodeValue && props.setPromoCodeValue(0);
                          props.setUsersPromoCode &&
                            props.setUsersPromoCode("");
                          props.setFullDiscount && props.setFullDiscount(false);
                          props.setOnLastStep && props.setOnLastStep(false);

                          return !prev;
                        })
                      }
                    />
                    <label className="CustomInput" htmlFor="promo_code">
                      Add promo code
                    </label>
                  </div>
                </div>
                {props.promoCodeVisible && (
                  <div className="FormBox">
                    <div className="FormLabel">
                      <label
                        htmlFor={"PromoValue"}
                        className="InputContainerLabels"
                      >
                        Promo code
                      </label>
                    </div>
                    <div className="FormField FlexContainer">
                      <input
                        autoFocus={true}
                        key={"promoval"}
                        className={`EditBox`}
                        name={"PromoValue"}
                        type={"text"}
                        id={"PromoValue"}
                        value={result ? result : props.usersPromoCode}
                        onChange={e => {
                          setResult(e.target.value);
                          props.setUsersPromoCode &&
                            props.setUsersPromoCode(e.target.value);
                          e.target.focus();
                        }}
                        spellCheck={false}
                      />
                      <div
                        className="Btn"
                        style={{ width: "200px", marginLeft: "5px" }}
                        onClick={() => setScanCode(true)}
                      >
                        Scan a QR code
                      </div>
                    </div>
                    {scanCode ? (
                      <div className="QRScannerMarginTop">
                        {noCamera ? (
                          <div className="QRScannerContainer">
                            <h4>
                              This device does not have a camera,
                              <br />
                              or your browser has blocked access to your camera
                            </h4>
                            <div
                              className="Btn"
                              onClick={() => uploadQRImage()}
                            >
                              Upload a QR code image file
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div
                          style={scannerLoading || noCamera ? hideScanner : {}}
                        >
                          <QrReader
                            ref={QRRef}
                            delay={300}
                            onError={handleError}
                            onScan={data => {
                              handleScan(data);
                            }}
                            onLoad={handleLoad}
                            legacyMode={legacyMode}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {QRError && (
                      <p className="ErrorMessageCenter">
                        There was a problem with your QR Code
                      </p>
                    )}
                    {errorMessage && (
                      <div className="ErrorMessage">{errorMessage}</div>
                    )}
                  </div>
                )}
                <button
                  className={`Btn`}
                  disabled={submitting}
                  onClick={() => {
                    //props.setActivePage!("PaymentCard")
                    verifyPromoCode(
                      props.subscription,
                      result || props.usersPromoCode
                    ); // TODO ADD 2nd param for sub id
                    if (props.onLastStep && props.disableCardInput) {
                      props.setActivePage!("Summary");
                      props.navigate && props.navigate("/register/summary");
                    } else {
                      props.registrationStepActions &&
                        props.registrationStepActions.setCurrentRegistrationStep(
                          "payment"
                        );
                      props.setActivePage!("PaymentCard");
                      props.navigate &&
                        props.navigate("/register/payment-card");
                    }
                  }}
                >
                  Save and continue
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Subscription;
