import React from "react";
import Helmet from "react-helmet";
import { BASE_URL } from "serverDetails";
import TopLogo from "./assets/icons/skippy-icon-circle.png";
import LeafLogoGreen from "./assets/icons/SVG/Asset-11.svg";
import AltLeafLogoGreen from "./assets/icons/SVG/Asset-13.svg";
import InsectLogoGreen from "./assets/icons/SVG/Asset-16.svg";
import LeafLogoRed from "./assets/icons/SVG/Asset-18.svg";
import AltLeafLogoRed from "./assets/icons/SVG/Asset-20.svg";
import SatelliteLogoGreen from "./assets/icons/SVG/Asset-22.svg";
import GridLogoGreen from "./assets/icons/SVG/Asset-24.svg";
import LeavesLogoGreen from "./assets/icons/SVG/Asset-26.svg";
import InsectLogoRed from "./assets/icons/SVG/Asset-3.svg";
import SatelliteLogoRed from "./assets/icons/SVG/Asset-5.svg";
import GridLogoRed from "./assets/icons/SVG/Asset-7.svg";
import LeavesLogoRed from "./assets/icons/SVG/Asset-9.svg";
import FlowerRatioRed from "./assets/icons/SVG/Asset-28.svg";
import FlowerRatioGreen from "./assets/icons/SVG/Asset-27.svg";
import CountGreen from "./assets/icons/SVG/Asset-14.svg";
import UniformityGreen from "./assets/icons/SVG/variance_good.svg";
import NitrogenRec from "./assets/icons/SVG/nitrogenRec.svg";
import RipeningRatio from "./assets/icons/1x/RipeningRatio.png";
import PodDevelopmentRatio from "./assets/icons/1x/PodDevelopmentRatio.png";
import SenescenceRatio from "./assets/icons/1x/SenescenceRatio.png";
import { DataSection } from "./DataSection";
import {
  hasImproved,
  insectDamageDetected,
  insectDamageImproved,
  isntNull,
  isPositive,
} from "./helpers";
import { Item, SummaryProps } from "./models";
import { DataItem } from "./DataItem";

type Glossary = {
  term: string;
  description: string;
  isKrpano?: boolean;
  isCardReport?: boolean;
};

const FieldReportGlossaryPage: React.FC<Omit<SummaryProps, "loadStatus">> = ({
  data,
  isKrpano,
  isPdf,
  isCardReport,
  setLoadStatus,
}) => {
  React.useEffect(() => {
    console.log('FieldReportGlossaryPage rendered', new Date());
    setLoadStatus((prev) => ({...prev, FieldReportGlossaryPage: true}));
  }, []);

  return (
    <>
      <Helmet>
        <title>Skippy Scout: Field Reports</title>
      </Helmet>
      <div
        className={`FieldReportsContainer ${
          isKrpano || isCardReport ? "KrpanoReport" : ""
        }
        ${isPdf ? "PdfReport" : ""}
        `}
        style={{ pageBreakBefore: "always", breakBefore: "always" }}
      >
        <img src={TopLogo} alt="logo" className="TopLogo"></img>
        {data.client_logo && (
          <img
            src={data.client_logo}
            alt="logo"
            className="TopLogo AgriiLogo"
          ></img>
        )}

        <div className="PageContentBox">
          <div className="PageTop GlossaryTop">
            <div className="PDFHeaderContainer">
              <h2>Our Reports Explained</h2>
              <h4></h4>
            </div>
          </div>
          <div className="DataSection GeneralGlossary">
            <div className="GeneralItemContainer">
              <div className="GeneralItem">
                <h4>Field Average Stats</h4>
                <p>
                  These are the average values for the whole field, calculated
                  using values from all scout points visited during the flight.
                </p>
              </div>
              <div className="GeneralItem">
                <h4>The 'Heat' Map</h4>
                <p>
                  This map overlay displays Skippy's estimation of the field's
                  status, highlighting where values are higher or lower. It uses
                  an algorithm to determine the most likely value between scout
                  points, based on the location and values of the scout points
                  themselves.
                </p>
              </div>
            </div>
            <div className="GeneralItemContainer">
              <div className="GeneralItem">
                <h4>Field Trends</h4>
                <p>
                  Trends are based on the previous flight and are designed to
                  show you if stats have gone up or down since the last flight.
                  They are only shown if the last flight was within 30 days.
                </p>
              </div>
              <div className="GeneralItem">
                <h4>Scout Point Stats</h4>
                <p>
                  These are the values determined by Skippy's analysis of the
                  image taken during the flight, at that scout point location.
                </p>
                <p>
                  You will also see the image itself, and the analysis overlay,
                  showing what Skippy has detected and based the statistics on.
                </p>
                <p>
                  Note these are not full resolution images, please view these
                  in-app or in your device library.
                </p>
              </div>
            </div>
          </div>
          <div className="DataSection Glossary">
            {/* <dl className="GlossaryTerms"> */}
            <div className="GlossaryTerms">
              <h4>Individual Stats</h4>
              {data.averageCropCover && (
                <div className="DataItemContainer Individual">
                  <DataItem
                    icon={LeavesLogoGreen}
                    value={"##%"}
                    description={"Crop Cover"}
                  />
                  <p className="Description">
                    This is the % of ground covered by healthy crop. 100% would
                    mean that healthy crop covers the entire image(s).
                  </p>
                </div>
              )}
              {data.averageGai && (
                <div className="DataItemContainer Individual">
                  <DataItem
                    icon={GridLogoGreen}
                    value={"#.##"}
                    description={"Crop GAI"}
                  />
                  <p className="Description">
                    Green Area Index. This stat gives a value estimate of leaf
                    cover. It is based on healthy crop only and can be used as
                    an estimate for crop biomass.
                  </p>
                </div>
              )}
              {data.averagePlantCount && (
                <div className="DataItemContainer Individual">
                  <DataItem
                    icon={CountGreen}
                    value={"##"}
                    description={"Crop Counts"}
                  />
                  <p className="Description">
                    Counts are given as a simple number of plants counted in the
                    image, and also a per square meter value, it is an average
                    based on the area covered by an image and the total plants
                    in the image.
                  </p>
                </div>
              )}
              {data.averageNdvi && (
                <div className="DataItemContainer Individual">
                  <DataItem
                    icon={SatelliteLogoGreen}
                    value={"#.##"}
                    description={"Field NDVI"}
                  />
                  <p className="Description">
                    This stat is a measurement for plant health/vigor based on
                    10m square resolution satellite data. It gives a good
                    general estimate for the amount of plant matter. Due to the
                    resolution, it may not be accurate for specific points in
                    the field and should be used only as a guide.
                  </p>
                </div>
              )}

              {data.averageWeedCover && (
                <div className="DataItemContainer Individual">
                  <DataItem
                    icon={AltLeafLogoRed}
                    value={"##%"}
                    description={"Weeds"}
                  />
                  <p className="Description">
                    This is the % of ground covered by weeds (plants that are
                    not crop).
                  </p>
                </div>
              )}
              {data.averageUnhealthyCrop && (
                <div className="DataItemContainer Individual">
                  <DataItem
                    icon={LeafLogoRed}
                    value={"##%"}
                    description={"Unhealthy Crop"}
                  />
                  <p className="Description">
                    The % of ground covered by unhealthy crop.
                  </p>
                </div>
              )}
              {data.averageYellowLeaves && (
                <div className="DataItemContainer Individual">
                  <DataItem
                    icon={LeafLogoRed}
                    value={"##%"}
                    description={"Yellow Leaves"}
                  />
                  <p className="Description">
                    The % of ground covered by crop with yellow leaves.
                  </p>
                </div>
              )}
              {data.averagePurpleLeaves && (
                <div className="DataItemContainer Individual">
                  <DataItem
                    icon={LeafLogoRed}
                    value={"##%"}
                    description={"Purple Leaves"}
                  />
                  <p className="Description">
                    The % of ground covered by crop with purple leaves.
                  </p>
                </div>
              )}
              {data.averageFloweringRatio && (
                <div className="DataItemContainer Individual">
                  <DataItem
                    icon={FlowerRatioGreen}
                    value={"##%"}
                    description={"Flowering Ratio"}
                  />
                  <p className="Description">
                    Add line for Flowering Ratio (or Flower Fraction) is the
                    percentage of the crop that is flowering vs not flowering.
                    100% would mean all of the crop is flowering. Note that the
                    Crop Cover measurement includes green crop and flowering
                    crop, the GAI measurement is green crop only.
                  </p>
                </div>
              )}
              {data.cropUniformity && (
                <div className="DataItemContainer Individual">
                  <DataItem
                    icon={UniformityGreen}
                    value={"##%"}
                    description={"Crop Uniformity"}
                  />
                  <p className="Description">
                    This value takes all scout point values into account and
                    tells you how even or uneven your crop is. A low value means
                    there is a big difference in values in the field. A high
                    value means the crop is very similar throughout the field.
                    100% would mean the crop is the same everywhere.
                  </p>
                </div>
              )}
              {data.nitrogenRec && (
                <div className="DataItemContainer Individual">
                  <DataItem
                    icon={NitrogenRec}
                    value={"##kg/ha"}
                    description={"Required N (EST)"}
                  />
                  <p className="Description">
                    Estimate of the amount of Nitrogen required for the crop at
                    that point, or for the field on average. Born out of
                    academic research, this is a calculation based on current
                    GAI, targeting an optimal GAI of 3.5 at the start of
                    flowering to deliver optimum yield. This calculation is
                    crop-only and does not include weeds.
                    <br />
                    <br />
                    NOTE: We recommend you take agronomic advice alongside this
                    estimate. You will need to take in account previous
                    cropping, soil N content, and any previous N applications
                    when using this estimate for your next application.{" "}
                    <em>
                      You can also use our crop-only GAI reading with your
                      preferred N calculator as an alternative.
                    </em>
                  </p>
                </div>
              )}

              {data.average_pod_development_ratio && (
                <div className="DataItemContainer Individual">
                  <DataItem
                    icon={PodDevelopmentRatio}
                    value={"##%"}
                    description={"Pod Development"}
                  />
                  <p className="Description">
                    This shows the percentage of each image/field that is going
                    through seed/pod fill &#38; development<strong>*</strong>.
                    In this case the plants will still be green and no flowers
                    will remain. <strong>*(BBCH Growth Stage 70-79)</strong>
                  </p>
                </div>
              )}
              {data.average_ripening_ratio && (
                <div className="DataItemContainer Individual">
                  <DataItem
                    icon={RipeningRatio}
                    value={"##%"}
                    description={"Ripening Ratio"}
                  />
                  <p className="Description">
                    This shows the percentage of ripening<strong>*</strong> in
                    each image/field. This is shown by the change in colour from
                    green to yellow throughout the pods.{" "}
                    <strong>*(BBCH Growth Stage 80-89)</strong>
                  </p>
                </div>
              )}
              {data.average_senescence_ratio && (
                <div className="DataItemContainer Individual">
                  <DataItem
                    icon={SenescenceRatio}
                    value={"##%"}
                    description={"Senescence"}
                  />
                  <p className="Description">
                    This aims to show the percentage of crop die-off or
                    senescence<strong>*</strong>, i.e. soon to be ready for
                    dessication, swathing and/or harvest. This is as the plants
                    turn a dark brown and dry out.{" "}
                    <strong>*(BBCH Growth Stage 90-99)</strong>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { FieldReportGlossaryPage };
