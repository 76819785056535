import * as React from "react";
import { RouteComponentProps, Link } from "@reach/router";
import {
  getFieldFromReportSection,
  getFieldReports,
  getKRPanoFieldReports,
} from "./FieldReportsApi";
import { useState } from "react";
import Helmet from "react-helmet";
import Spinner from "react-spinkit";
import queryString from "query-string";
import ScoutPointPages from "./ScoutPointPages";
import { format } from "date-fns";
import { FieldReportSummaryPage } from "./FieldReportSummaryPage";
import { DataUnavailablePage } from "./DataUnavailablePage";
import { FieldReportsData, FieldReportsDto, LoadStatus } from "./models";
import { parse } from "@babel/core";
import { FieldReportGlossaryPage } from "./FieldReportGlossaryPage";
import { FieldReportNotesPage } from "./FieldReportNotesPage";
const Logo = "/content/images/Icon_NoResult.png";
const ErrorLogo = "/content/images/Icon_Warning.png";

declare global {
  interface Window {
    selectpdf?: any;
  }
}

const FieldReportsPage = (props: RouteComponentProps) => {
  const [data, setData] = useState<FieldReportsData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isKrpano, setIsKrpano] = useState<boolean>(false);
  const [isPdf, setIsPdf] = useState<boolean>(false);
  const [tokenError, setTokenError] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loadStatus, setLoadStatus] = useState<LoadStatus>({
    FieldReportSummaryPage: false,
    FieldReportNotesPage: false,
    ScoutPointPages: false,
    FieldReportGlossaryPage: false,
  })
  const search = props.location ? props.location.search : "";
  const parsed = queryString.parse(search);
  const id = parsed.flight_id as string;
  const token = parsed.token;
  const guid = parsed.guid;
  const isReportPopup = parsed.fieldReportsPopup as string;
  const pdfOnly = parsed.bPdf && parsed.bPdf === "true" ? true : false;
  const isCardReport = parsed.isCardReport === "true" ? true : false;
  if (!isPdf && pdfOnly) {
    setIsPdf(pdfOnly);
  }

  React.useEffect(() => {
    if (
      data && 
      isPdf && 
      loadStatus.FieldReportGlossaryPage && 
      loadStatus.ScoutPointPages && 
      loadStatus.FieldReportSummaryPage && 
      ((data.notes && loadStatus.FieldReportNotesPage) || !data.notes)
    ) {
      if (typeof (window.selectpdf) == "object") {
        window.selectpdf.start();
      }
    }
  }, [loadStatus]);

  React.useEffect(() => {
    if (parsed) {
      (async () => {
        if (id && token) {
          setLoading(true);
          const result = await getFieldReports(id, token);
          if (result.response) {
            const scoutPointData: FieldReportsData = parseData(
              result.response.details
            );
            setData(scoutPointData);
          }
          if (result.errors) {
            switch (result.errors.response.data.errors[0].message_code) {
              case "Invalid_Token":
                setGeneralError(true);
                setErrorMessage(
                  "An  invalid token has been used. Please try again."
                );
                break;
              case "Invalid_Field_ID":
                setGeneralError(true);
                setErrorMessage(
                  "An  invalid flight ID has been used. Please try again."
                );
                break;
              default:
                setGeneralError(true);
                setErrorMessage(
                  "Oops, something went wrong. Please try again."
                );
            }
          }
        } else if (id && guid) {
          setIsKrpano(true);

          setLoading(true);
          const result = await getKRPanoFieldReports(id, guid);
          if (result.response) {
            const scoutPointData: FieldReportsData = parseData(
              result.response.details
            );
            setData(scoutPointData);
          }
          if (result.errors) {
            switch (result.errors.response.data.errors[0].message_code) {
              case "Invalid_Token":
                setGeneralError(true);
                setErrorMessage(
                  "An  invalid token has been used. Please try again."
                );
                break;
              case "Invalid_Field_ID":
                setGeneralError(true);
                setErrorMessage(
                  "An  invalid flight ID has been used. Please try again."
                );
                break;
              default:
                setGeneralError(true);
                setErrorMessage(
                  "Oops, something went wrong. Please try again."
                );
            }
          }
        }
        setLoading(false);
      })();
    }
  }, []);

  React.useEffect(() => {
    if (isReportPopup && id) {
      (async () => {
        setLoading(true);
        const result = await getFieldFromReportSection(parseInt(id));
        if (result.response) {
          const scoutPointData: FieldReportsData = parseData(
            result.response.details
          );
          setData(scoutPointData);
        }
        if (result.errors && result.errors.response) {
          switch (result.errors.response.data.errors[0].message_code) {
            case "Invalid_Token":
              setGeneralError(true);
              setErrorMessage(
                "An  invalid token has been used. Please try again."
              );
              break;
            case "Invalid_Field_ID":
              setGeneralError(true);
              setErrorMessage(
                "An  invalid flight ID has been used. Please try again."
              );
              break;
            default:
              setGeneralError(true);
              setErrorMessage("Oops, something went wrong. Please try again.");
          }
        } else if (!result.response) {
          // handle missing id / token
          if (!id) {
            setGeneralError(true);
            setErrorMessage("Invalid ID. Please try again.");
          } else {
            setGeneralError(true);
            setErrorMessage("Oops, something went wrong. Please try again.");
          }
        }
        setLoading(false);
      })();
    }
  }, [isReportPopup, id]);

  const parseData = (dto: FieldReportsDto): FieldReportsData => {
    const data: FieldReportsData = {
      notes: dto.notes,
      fieldName: dto.field_name,
      fieldId: dto.fields_id,
      flightId: parseInt(id),
      token: token ? token : "",
      fieldPoint: dto.field_point ? dto.field_point.split(",") : null,
      farmName: dto.farm_name,
      cropName: dto.crop_name,
      client_logo: dto.client_logo,
      plantDate: dto.report_date
        ? format(new Date(dto.report_date), "do MMMM yyyy")
        : null,
      nWeeksSincePlant: dto.nWeeksSincePlant ? dto.nWeeksSincePlant : null,
      fieldArea: dto.field_area ? dto.field_area : null,
      averageCropCover:
        dto.average_crop_cover && dto.average_crop_cover
          ? dto.average_crop_cover
          : null,
      averagePlantCount:
        dto.average_count_per_area && dto.average_count_per_area
          ? dto.average_count_per_area
          : null,
      bUseAcres: Boolean(dto.b_use_acres),
      averageGai: dto.average_gai && dto.average_gai,
      averageWeedCover: dto.average_weed_cover && dto.average_weed_cover,
      averageNdvi: dto.average_ndvi && dto.average_ndvi,
      averageUnhealthyCrop:
        dto.average_unhealthy_leaves && dto.average_unhealthy_leaves,
      averageInsectDamage:
        dto.average_insect_damage_change && dto.average_insect_damage_change,
      averageYellowLeaves:
        dto.average_yellow_leaves && dto.average_yellow_leaves,
      averagePurpleLeaves:
        dto.average_purple_leaves && dto.average_purple_leaves,
      averageFloweringRatio:
        dto.average_flowering_ratio && dto.average_flowering_ratio,
      cropUniformity: dto.crop_uniformity && dto.crop_uniformity,
      cropUniformityChange:
        dto.crop_uniformity_change && dto.crop_uniformity_change,
      nitrogenRec: dto.average_n_rec && dto.average_n_rec,
      nitrogenRecChange: dto.average_n_rec_change && dto.average_n_rec_change,
      averageCropCoverChange:
        dto.average_crop_cover_change && dto.average_crop_cover_change
          ? dto.average_crop_cover_change
          : null,
      averagePlantCountChange:
        dto.average_count_per_area_change && dto.average_count_per_area_change
          ? dto.average_count_per_area_change
          : null,
      averageGaiChange: dto.average_gai_change && dto.average_gai_change,
      averageWeedCoverChange:
        dto.average_weed_cover_change && dto.average_weed_cover_change,
      averageNdviChange: dto.average_ndvi_change && dto.average_ndvi_change,
      averageUnhealthyCropChange:
        dto.average_unhealthy_leaves_change &&
        dto.average_unhealthy_leaves_change,
      averageInsectDamageChange: null,
      averageYellowLeavesChange:
        dto.average_yellow_leaves_change && dto.average_yellow_leaves_change,
      averagePurpleLeavesChange:
        dto.average_purple_leaves_change && dto.average_purple_leaves_change,
      averageFloweringRatioChange:
        dto.average_flowering_ratio_change &&
        dto.average_flowering_ratio_change,
      zoomLevel: dto.zoom_level ? dto.zoom_level : null,
      insectDamage: dto.insect_damage ? dto.insect_damage : null,
      fieldGeometry: dto.field_geometry,
      bNoOverallTrends: dto.bNoOverallTrends,
      heatmapInfo: dto.heatmap_info ? dto.heatmap_info : null,
      ndviDate: dto.ndvi_date ? dto.ndvi_date : null,
      lastNdviDate: dto.last_ndvi_date ? dto.last_ndvi_date : null,
      lastFlightDate: dto.last_flight_date ? dto.last_flight_date : null,
      heatmapUrl: dto.heatmap_url ? dto.heatmap_url : null,
      scoutPoints: dto.scout_points.map(d => {
        return {
          bShowTrends:
            d.agrohix_ai_image &&
            d.agrohix_ai_image.bShowTrends &&
            d.agrohix_ai_image.bShowTrends,
          scoutPointId: d.id,
          scoutPointLabel: d.label && d.label,
          scoutDate:
            dto.report_date &&
            format(new Date(dto.report_date), "do MMMM yyyy"),
          cropCover:
            d.agrohix_ai_image && d.agrohix_ai_image.crop_cover
              ? d.agrohix_ai_image.crop_cover
              : null,
          plantCount:
            d.agrohix_ai_image && d.agrohix_ai_image.count
              ? d.agrohix_ai_image.count
              : null,
          plantCountSquared:
            d.agrohix_ai_image &&
            d.agrohix_ai_image.count_per_area &&
            d.agrohix_ai_image.count_per_area,
          ndvi: d.ndvi && d.ndvi ? d.ndvi : null,
          gai:
            d.agrohix_ai_image && d.agrohix_ai_image.gai
              ? d.agrohix_ai_image.gai
              : null,
          weedCover:
            d.agrohix_ai_image && d.agrohix_ai_image.weed_cover
              ? d.agrohix_ai_image.weed_cover
              : null,
          nitrogenRec:
            d.agrohix_ai_image && d.agrohix_ai_image.n_rec
              ? d.agrohix_ai_image.n_rec
              : null,
          aiImageId: d.agrohix_ai_image && d.agrohix_ai_image.id,
          scoutPointImageId: d.scout_point_image && d.scout_point_image.id,
          unhealthyCrop:
            d.agrohix_ai_image && d.agrohix_ai_image.unhealthy_leaves,
          yellowLeaves: d.agrohix_ai_image && d.agrohix_ai_image.yellow_leaves,
          purpleLeaves: d.agrohix_ai_image && d.agrohix_ai_image.purple_leaves,
          floweringRatio:
            d.agrohix_ai_image && d.agrohix_ai_image.flowering_ratio
              ? d.agrohix_ai_image.flowering_ratio
              : null,
          averageGrowthStageAAB:
            d.agrohix_ai_image && d.agrohix_ai_image.growth_stage_aab
              ? d.agrohix_ai_image.growth_stage_aab
              : null,
          averageGrowthStageBBCH:
            d.agrohix_ai_image && d.agrohix_ai_image.growth_stage_bbch
              ? d.agrohix_ai_image.growth_stage_bbch
              : null,
          senescence_ratio:
            d.agrohix_ai_image && d.agrohix_ai_image.senescence_ratio
              ? d.agrohix_ai_image.senescence_ratio
              : null,
          pod_development_ratio:
            d.agrohix_ai_image && d.agrohix_ai_image.pod_development_ratio
              ? d.agrohix_ai_image.pod_development_ratio
              : null,
          ripening_ratio:
            d.agrohix_ai_image && d.agrohix_ai_image.ripening_ratio
              ? d.agrohix_ai_image.ripening_ratio
              : null,
          insectDamage: d.agrohix_ai_image && d.agrohix_ai_image.insect_damage,
          latitude: d.latitude,
          longitude: d.longitude,
          trendCropCover:
            d.trends && d.trends.crop_cover_change
              ? d.trends.crop_cover_change
              : null,
          trendGai:
            d.trends && d.trends.gai_change ? d.trends.gai_change : null,
          trendWeedCover:
            d.trends && d.trends.weed_cover_change
              ? d.trends.weed_cover_change
              : null,
          trendNdvi: d.trends && d.ndvi_change ? d.ndvi_change : null,
          trendUnhealthyCrop:
            d.trends && d.trends.unhealthy_leaves_change
              ? d.trends.unhealthy_leaves_change
              : null,
          trendYellowLeaves:
            d.trends && d.trends.yellow_leaves_change
              ? d.trends.yellow_leaves_change
              : null,
          trendPurpleLeaves:
            d.trends && d.trends.purple_leaves_change
              ? d.trends.purple_leaves_change
              : null,
          trendFloweringRatio:
            d.trends && d.trends.flowering_ratio_change
              ? d.trends.flowering_ratio_change
              : null,
          trendInsectDamage:
            d.trends && d.trends.insect_damage_change
              ? d.trends.insect_damage_change
              : null,
          trendRipeningRatio:
            d.trends && d.trends.ripening_ratio_change
              ? d.trends.ripening_ratio_change
              : null,
          trendSenescenceRatio:
            d.trends && d.trends.senescence_ratio_change
              ? d.trends.senescence_ratio_change
              : null,
          trendPodDevelopmentRatio:
            d.trends && d.trends.pod_development_ratio_change
              ? d.trends.pod_development_ratio_change
              : null,
          trendAABChange:
            d.trends && d.trends.growth_stage_aab_change
              ? d.trends.growth_stage_aab_change
              : null,
          trendBBCHChange:
            d.trends && d.trends.growth_stage_bbch_change
              ? d.trends.growth_stage_bbch_change
              : null,
          bNoScoutpointTrends: !d.trends ? true : false,
          bNoScoutPoint: !d.scout_point_image ? false : false,
          aiImageUrl:
            d.agrohix_ai_image && d.agrohix_ai_image.image_url
              ? d.agrohix_ai_image.image_url
              : null,
          scoutPointImageUrl:
            d.scout_point_image && d.scout_point_image.image_url
              ? d.scout_point_image.image_url
              : null,
          scoutPointMapImageUrl: d.map_url ? d.map_url : null,
          bNoAgrohixAIData: !d.agrohix_ai_image ? true : false,
          base_mapbox_image: dto.base_mapbox_image,
          icon_image: dto.icon_image,
          pixel_x: d.pixel_x,
          pixel_y: d.pixel_y,
        };
      }),
      average_pod_development_ratio: dto.average_pod_development_ratio,
      average_ripening_ratio: dto.average_ripening_ratio,
      average_senescence_ratio: dto.average_senescence_ratio,
      averageGrowthStageAAB: dto.average_growth_stage_aab
        ? dto.average_growth_stage_aab
        : null,
      averageGrowthStageBBCH: dto.average_growth_stage_bbch
        ? dto.average_growth_stage_bbch
        : null,
      average_pod_development_ratio_change:
        dto.average_pod_development_ratio_change,
      average_ripening_ratio_change: dto.average_ripening_ratio_change,
      average_senescence_ratio_change: dto.average_senescence_ratio_change,
      averageGrowthStageAABChange: dto.average_growth_stage_aab_change
        ? dto.average_growth_stage_aab_change
        : null,
      averageGrowthStageBBCHChange: dto.average_growth_stage_bbch_change
        ? dto.average_growth_stage_bbch_change
        : null,
      base_mapbox_image: dto.base_mapbox_image,
      icon_image: dto.icon_image,
    };
    return data;
  };

  return (
    <>
      <Helmet>
        <title>Skippy Scout: Field Report</title>
      </Helmet>
      {loading && (
        <span>
          <Spinner name="three-bounce" fadeIn="none" />
        </span>
      )}
      {data && (
        <>
          <FieldReportSummaryPage
            data={data}
            isKrpano={isKrpano}
            isPdf={isPdf}
            isCardReport={isCardReport}
            loadStatus={loadStatus}
            setLoadStatus={setLoadStatus}
          />
          {data.notes && (
            <FieldReportNotesPage
              notes={data.notes}
              isKrpano={isKrpano}
              isPdf={isPdf}
              isCardReport={isCardReport}
              setLoadStatus={setLoadStatus}
            />
          )}
          <ScoutPointPages
            data={data}
            isKrpano={isKrpano}
            isPdf={isPdf}
            isCardReport={isCardReport}
            loadStatus={loadStatus}
            setLoadStatus={setLoadStatus}
          />
          <FieldReportGlossaryPage
            data={data}
            isKrpano={isKrpano}
            isPdf={isPdf}
            isCardReport={isCardReport}
            setLoadStatus={setLoadStatus}
          />
        </>
      )}
      {tokenError && (
        <div className="ErrorPageContainer">
          <img
            src={Logo}
            alt="Skippy Scout"
            style={{ height: "80px", width: "80px" }}
          />
          <h1>Sorry, that page doesn't exist</h1>
          <Link to="/" className="Btn">
            Return to Login
          </Link>
        </div>
      )}
      {generalError && (
        <div className="ErrorPageContainer">
          <img
            src={ErrorLogo}
            alt="Skippy Scout"
            style={{ height: "60px", width: "60px" }}
          />
          <h2>{errorMessage}</h2>
          {!isReportPopup && (
            <Link to="/" className="Btn">
              Return to Login
            </Link>
          )}
        </div>
      )}
    </>
  );
};

export default FieldReportsPage;
