import { produce } from "immer";
import { AxiosRequestConfig } from "axios";
import { AppState } from "reducers/rootReducer";
import { createSelector } from "reselect";
import { Maybe } from "purify-ts/Maybe";
import api from "api";
import { AuthState } from "./rootReducer";
import {
  map,
  tap,
  switchMap,
  mergeMap,
  takeUntil,
  catchError,
  ignoreElements,
} from "rxjs/operators";
import { of, from, throwError } from "rxjs";

import {
  AuthStateStatus,
  NormalizedNavItems,
  AccountTypeDetails,
  TokenDetails,
  NavigationDetails,
  Credentials,
  TwoFactorCredentials,
} from "types/AuthTypes";
import { AxiosError } from "axios";
import { AUTH_URL, endpoints } from "serverDetails";
import { combineEpics, ofType, ActionsObservable } from "redux-observable";
import { navigate } from "@reach/router";

/* Constants */

const ATTEMPT_SIGN_IN = "app/auth/ATTEMPT_SIGN_IN";
const ATTEMPT_TFA_SIGN_IN = "app/auth/ATTEMPT_TFA_SIGN_IN";
const SIGN_OUT = "app/auth/SIGN_OUT";
const RESET_SIGN_IN_ERRORS = "app/auth/RESET_SIGN_IN_ERRORS";
const NEW_PASSWORD_REQUESTED = "app/auth/NEW_PASSWORD_REQUESTED";
const SIGN_IN_ERROR = "app/auth/SIGN_IN_ERROR";
const SIGN_IN_SUCCESS = "app/auth/SIGN_IN_SUCCESS";
const SIGN_IN_CANCEL = "app/auth/SIGN_IN_CANCEL";
const SIGN_IN_VALIDATION_ERROR = "app/auth/SIGN_IN_VALIDATION_ERROR";
const SIGN_IN_PENDING = "app/auth/SIGN_IN_PENDING";
const SIGN_IN_REQUIRE_TWO_FACTOR = "app/auth/SIGN_IN_REQUIRE_TWO_FACTOR";
const GET_ACCOUNT_TYPE = "app/auth/GET_ACCOUNT_TYPE";
const LOAD_AUTH_STATE = "app/auth/LOAD_AUTH_STATE";
const PASSWORD_RESET = "app/auth/PASSWORD_RESET";
const NOT_SIGNED_IN = "app/auth/NOT_SIGNED_IN";
const RESTRICT_ACCESS = "app/auth/RESTRICT_ACCESS";
const CHECK_ACCESS = "app/auth/CHECK_ACCESS";
const CHECK_ACCESS_SUCCESS = "app/auth/CHECK_ACCESS_SUCCESS";
const CHECK_ACCESS_ERROR = "app/auth/CHECK_ACCESS_ERROR";

type ErrorPayload = { errorList: { fieldName: string; messageCode: string }[] };
type AuthAction =
  | { type: typeof GET_ACCOUNT_TYPE }
  | { type: typeof ATTEMPT_SIGN_IN; payload: Credentials }
  | { type: typeof ATTEMPT_TFA_SIGN_IN; payload: TwoFactorCredentials }
  | { type: typeof LOAD_AUTH_STATE }
  | { type: typeof SIGN_IN_SUCCESS; payload: TokenDetails }
  | { type: typeof SIGN_IN_PENDING }
  | { type: typeof SIGN_IN_ERROR; payload: AxiosError }
  | { type: typeof SIGN_IN_VALIDATION_ERROR; payload: ErrorPayload }
  | { type: typeof SIGN_IN_PENDING }
  | { type: typeof SIGN_IN_CANCEL }
  | { type: typeof SIGN_OUT }
  | { type: typeof SIGN_IN_REQUIRE_TWO_FACTOR }
  | { type: typeof RESET_SIGN_IN_ERRORS }
  | { type: typeof PASSWORD_RESET }
  | { type: typeof NEW_PASSWORD_REQUESTED }
  | { type: typeof NOT_SIGNED_IN }
  | { type: typeof RESTRICT_ACCESS; payload: NavigationDetails }
  | { type: typeof CHECK_ACCESS }
  | { type: typeof CHECK_ACCESS_SUCCESS; payload: string }
  | { type: typeof CHECK_ACCESS_ERROR };

/* Reducer */

const initialAuthState: AuthState = {
  status: "start" as AuthStateStatus,
  subscriptionStatus: "Initial",
  errors: [] as { fieldName: string; messageCode: string }[],
  appVisible: false,
  message: "",
  navigation: {
    result: [],
    entities: { children: {}, items: {} },
  } as NormalizedNavItems,
  client_type: "Standard",
  primary_user: true,
  b_users_feature: false,
  b_can_add_account_users: false,
  client_logo: "",
};

export const reducer = (
  state: AuthState = initialAuthState,
  action: AuthAction
): AuthState =>
  produce(state, draft => {
    switch (action.type) {
      case ATTEMPT_SIGN_IN:
      case NEW_PASSWORD_REQUESTED:
      case LOAD_AUTH_STATE:
        draft.message = "";
        draft.status = "pending";
        break;
      case NOT_SIGNED_IN:
        draft.status = "start";
        draft.appVisible = true;
        break;
      case SIGN_IN_SUCCESS:
        draft.status = "authenticated" as AuthStateStatus;
        draft.appVisible = true;
        draft.client_type = action.payload.client_type;
        draft.primary_user = action.payload.primary_user;
        draft.b_users_feature = action.payload.b_users_feature;
        // draft.navigation = normalize(action.payload.navigationItems, navSchema);
        if (action.payload.panos) {
          draft.panos = action.payload.panos;
        }
        draft.b_can_add_account_users = action.payload.b_can_add_account_users;
        draft.b_users_feature = action.payload.b_users_feature;
        draft.client_logo = action.payload.client_logo;
        // draft.navigation = normalize(action.payload.navigationItems, navSchema);
        break;
      case PASSWORD_RESET:
        draft.message = "Password successfully reset, please log in.";
        break;
      case SIGN_IN_REQUIRE_TWO_FACTOR:
        draft.status = "require2FA";
        break;
      case SIGN_IN_VALIDATION_ERROR:
        draft.status = "error";
        draft.errors = action.payload.errorList;
        draft.message =
          "We couldn't sign you in with those details. Please check they are correct and try again.";
        break;
      case SIGN_IN_ERROR:
        draft.status = "error";
        draft.message = "";
        break;
      case RESET_SIGN_IN_ERRORS:
        draft.errors = [];
        draft.message = "";
        break;
      case SIGN_IN_CANCEL:
        draft.errors = [];
        draft.message = "";
        draft.status = "start";
        break;
      case SIGN_OUT:
        draft.status = "signedOut";
        draft.client_type = "Standard";
        draft.primary_user = false;
        draft.b_can_add_account_users = false;
        localStorage.clear();
        draft.b_can_add_account_users = false;
        draft.panos = null;
        break;
      case CHECK_ACCESS_SUCCESS:
        draft.subscriptionStatus = action.payload;
        break;
    }
  });

/* Action Creators */

export const signIn = (payload: Credentials): AuthAction => ({
  type: ATTEMPT_SIGN_IN,
  payload,
});

export const tfaSignIn = (payload: TwoFactorCredentials): AuthAction => ({
  type: ATTEMPT_TFA_SIGN_IN,
  payload,
});

export const passwordReset = (): AuthAction => ({
  type: PASSWORD_RESET,
});

export const passwordRequested = (): AuthAction => ({
  type: NEW_PASSWORD_REQUESTED,
});

export const signOut = (): AuthAction => ({
  type: SIGN_OUT,
});

export const loadAuthState = (): AuthAction => ({
  type: LOAD_AUTH_STATE,
});

export const getAccountTypeDetails = (): AuthAction => ({
  type: GET_ACCOUNT_TYPE,
});

/* Side Effects */

/*************************************************************************
 *                  LOAD AUTH STATE FROM LOCAL STORAGE                   *
 *************************************************************************/

const checkAuthState = (): boolean => {
  const tokenDetailsString = localStorage.getItem("TOKEN_DETAILS");
  if (tokenDetailsString === null) {
    return false;
  }
  const tokenDetails: TokenDetails = JSON.parse(tokenDetailsString);
  if (tokenDetails.access_token == null || tokenDetails.refresh_token == null) {
    return false;
  }
  return true;
};

const loadAuthStateEpic = (action$: ActionsObservable<any>) => {
  return action$.pipe(
    ofType(LOAD_AUTH_STATE),
    map(checkAuthState),
    switchMap(s => {
      let detailsString = Maybe.fromNullable(
        localStorage.getItem("TOKEN_DETAILS")
      );
      let newAction = detailsString
        .chain(detail => Maybe.encase(() => JSON.parse(detail)))
        .caseOf({
          Just: payload => ({ type: SIGN_IN_SUCCESS, payload }),
          Nothing: () => ({ type: NOT_SIGNED_IN }),
        });
      return s ? of(newAction) : of({ type: NOT_SIGNED_IN });
    })
  );
};

const clearStorageEpic = (action$: ActionsObservable<any>) => {
  return action$.pipe(
    ofType(SIGN_OUT),
    tap(() => localStorage.clear()),
    ignoreElements()
  );
};

/**********************************************************************
 *                            LOGIN EPICS                             *
 **********************************************************************/

function makeRequest<T>(config: AxiosRequestConfig) {
  return from(api.request<T>(config));
}

/**
 * Helper to take a token details object and save it in localstorage.
 */
const saveTokenDetails = (data: { details: TokenDetails }) => {
  localStorage.setItem("TOKEN_DETAILS", JSON.stringify(data.details));
};

const loginEpic = (action$: ActionsObservable<any>) => {
  return action$.pipe(
    ofType(ATTEMPT_SIGN_IN),
    switchMap(attempt =>
      makeRequest<any>({
        url: AUTH_URL + "login",
        data: { ...attempt.payload },
        method: "post",
      }).pipe(
        mergeMap(res =>
          res.data.errors.length === 0
            ? of(res.data)
            : throwError(res.data.errors)
        ),
        tap(saveTokenDetails),
        map(res => ({ type: SIGN_IN_SUCCESS, payload: res.details })),
        map(res => {
          navigate("/home");
          return res;
        }),
        catchError((error: any) => {
          return of({
            type: SIGN_IN_VALIDATION_ERROR,
            payload: {
              errorList: [
                {
                  fieldName: error.response
                    ? error.response.data.errors[0].field_name
                    : "",
                  messageCode: error.response
                    ? error.response.data.errors[0].message_code
                    : "",
                },
              ],
            },
          });
        }),
        takeUntil(
          action$.pipe(
            ofType(SIGN_OUT, SIGN_IN_ERROR, SIGN_IN_VALIDATION_ERROR)
          )
        )
      )
    )
  );
};

const accessCodesEpic = (action$: ActionsObservable<any>) => {
  return action$.pipe(
    ofType(SIGN_IN_SUCCESS, CHECK_ACCESS),
    switchMap(() =>
      makeRequest<any>({
        url: endpoints.subscription.refreshAccessSubscription,
      }).pipe(
        mergeMap(res =>
          res.data.errors.length === 0
            ? of(res.data)
            : throwError(res.data.errors)
        ),
        map(data => ({
          type: CHECK_ACCESS_SUCCESS,
          payload: data.details.subscription_status,
        })),
        catchError((error: any) => {
          console.log("error catch", error);
          return of({ type: CHECK_ACCESS_ERROR, payload: error });
        })
      )
    )
  );
};

//const activateEpic = (action$: ActionsObservable<any>) => {
//  return action$.pipe(
//    ofType("ACTIVATE"),
//    switchMap((action: any) => of(action.payload).pipe(
//      map(res => ({details: res})),
//      tap(saveTokenDetails),
//      map(res => ({ type: SIGN_IN_SUCCESS, payload: res.details })),
//      catchError((error: any) => {
//        return of({ type: SIGN_IN_VALIDATION_ERROR, payload: { errorList: [{ fieldName: error.response.data.errors[0].field_name, messageCode: error.response.data.errors[0].message_code }] } });
//      }),
//      takeUntil(
//        action$.pipe(
//          ofType(SIGN_OUT, SIGN_IN_ERROR, SIGN_IN_VALIDATION_ERROR)
//        )
//      )
//    )
//    )

//  )
//}

const loginTwoFactorEpic = (action$: ActionsObservable<any>) => {
  return action$.pipe(
    ofType(ATTEMPT_TFA_SIGN_IN),
    switchMap(attempt =>
      makeRequest<any>({
        url: AUTH_URL + "login-tfa",
        data: { ...attempt.payload },
        method: "post",
      })
    ),
    mergeMap(res =>
      res.data.errors.length === 0 ? of(res.data) : throwError(res.data.errors)
    ),
    tap(saveTokenDetails),
    catchError((error: any) => {
      return of({ type: SIGN_IN_VALIDATION_ERROR, payload: error });
    }),
    takeUntil(
      action$.pipe(ofType(SIGN_OUT, SIGN_IN_ERROR, SIGN_IN_VALIDATION_ERROR))
    )
  );
};

// const getDetailsEpic = (action$: ActionsObservable<any>) => {
//   return action$.pipe(
//     ofType(GET_ACCOUNT_TYPE),
//     switchMapTo(
//       makeRequest({ url: API_CLIENT_URL + "get-details" }).pipe(
//         tap(({ data }) =>
//           localStorage.setItem("ACCOUNT_TYPE", JSON.stringify(data))
//         ),
//         map(({ data }) => ({ type: GOT_DETAILS, payload: data })),
//         catchError(err => of({ type: SIGN_OUT, payload: err }))
//       )
//     )
//   );
// };

export const authEpic = combineEpics(
  loginEpic,
  loginTwoFactorEpic,
  loadAuthStateEpic,
  // getDetailsEpic,
  clearStorageEpic,
  //activateEpic,
  accessCodesEpic
);

/* Selectors */

export const selectNormalisedNav = (state: AppState) => state.auth.navigation;

export const selectPermittedPaths = createSelector(selectNormalisedNav, nav => {
  const children =
    nav.entities.children != null
      ? Object.values(nav.entities.children)
          .map((x: any) => x.path)
          .concat("")
      : [];
  const items =
    nav.entities.items != null
      ? Object.values(nav.entities.items)
          .map((x: any) => x.path)
          .concat("")
      : [];
  return children.concat(items);
});

// Maybe.fromNullable takes a nullable value and produces either Just(value) or Nothing.
// Maybe.encase takes a function which may throw and produces a Nothing if it does.
export const selectAccountType = (): Maybe<AccountTypeDetails> => {
  const detailsString = Maybe.fromNullable(
    localStorage.getItem("ACCOUNT_TYPE")
  );
  return detailsString.chain(detail => Maybe.encase(() => JSON.parse(detail)));
};

export const selectAuthStatus = (state: AppState): AuthStateStatus =>
  state.auth.status;

export const selectIsAuthenticated = createSelector(
  selectAuthStatus,
  authStatus => authStatus === "authenticated"
);

export const selectSignInResponse = (state: unknown): Maybe<TokenDetails> => {
  const detailsString = Maybe.fromNullable(
    localStorage.getItem("TOKEN_DETAILS")
  );
  return detailsString.chain(detail => Maybe.encase(() => JSON.parse(detail)));
};

export const selectAuthErrors = (state: AppState) => state.auth.errors;

export const selectPanos = (state: AppState) => state.auth.panos;

export const selectMessage = (state: AppState) => state.auth.message;

export const selectTokenDetails = createSelector(
  [selectSignInResponse],
  response =>
    response.map(response => ({
      access_token: response.access_token,
      refresh_token: response.refresh_token,
    }))
);
